import React, { FC } from 'react'

import ProjectRow from './project'
import ProjectPage from './page'

import Pulse from './pulse'
import Compost from './compost'
import ConductionSeries from './conduction_series'
import Wearehere from './wearehere'
import Mezcal from './mezcal'
import HearHere from './hearhere'
import Underweb from './underweb'
import Fundamental from './fundamental'
import Standup from './standup'
import Userradio from './userradio'
import DataDada from './datadada'
import Lechero from './lechero'

import { Project } from './types'

export const projects: Project[] = [
  Pulse,
  Compost,
  ConductionSeries,
  Wearehere,
  Mezcal,
  HearHere,
  Underweb,
  Userradio,
  Lechero,
  DataDada,
  Standup,
  Fundamental
]

const Proyectos: FC<{ pid: string }> = ({
  pid
}) => {
  const project = projects.find(p => p.key === pid)

  return project && project.key
    ? (<ProjectPage project={project} />)
    : (
      <React.Fragment>
        {projects.map(p => <ProjectRow pid={pid} project={p} key={p.key} open={pid === p.key} />)}
      </React.Fragment>
    )
}
export default Proyectos
